// extracted by mini-css-extract-plugin
export var container = "styles-module--container--QW-ag";
export var label1 = "styles-module--label1--quC3u";
export var label2 = "styles-module--label2--0sH+h";
export var titleContainer = "styles-module--titleContainer--r37AG";
export var hireContainer = "styles-module--hireContainer--3OyrB";
export var imageHire = "styles-module--imageHire--IeY7j";
export var airImage = "styles-module--airImage--sdzTZ";
export var contentWrapper = "styles-module--contentWrapper--ZZV6F";
export var contentContainer = "styles-module--contentContainer--14yp5";
export var semiCircle = "styles-module--semiCircle--HNoMD";
export var signupButton = "styles-module--signupButton--dMejJ";