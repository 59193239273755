import React from "react";
import { data } from "../../../mocks/data";
import * as styles from "./styles.module.scss";

export function SectionProviderMobile({ id }) {
  return (
    <div className={styles.container} id={id}>
      <img src='/static/images/be-a-provider.png' alt='logo' className={styles.imgMain}/>
      <div className={styles.containerText}>
        {data.provider}
      </div>
      <button className={styles.signupButton}>CRIAR CONTA</button>
    </div>
  )
}