import React from 'react';
import * as styles from './styles.module.scss';
import { FaCheck } from '@react-icons/all-files/fa/FaCheck';
import { data } from '../../../mocks/data';

const FEATURES = [
  'Funcionalidade ou problema que resolve 1',
  'Funcionalidade ou problema que resolve 2',
  'Funcionalidade ou problema que resolve 3',
  'Funcionalidade ou problema que resolve 4',
]

export default function SecionFeatures({ id }) {
  return (
    <div className={styles.container} id={id}>
      <div className={styles.center}>
        <img src='/static/images/stairs.png' alt='logo' className={styles.imgStair} />
      </div>
      <div className={styles.center}>
        <h1 className={styles.title}>Funcionalidades</h1>
        <div className={styles.wrapperFeatures}>
          {data.features.map(feature => (
              <div className={styles.feature}>
                <FaCheck className={styles.textGreen} /> {feature}
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}