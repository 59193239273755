import React, { useRef, useState } from 'react';
import * as styles from './styles.module.scss';
import { FaTwitter } from '@react-icons/all-files/fa/FaTwitter';
import { FaFacebookF } from '@react-icons/all-files/fa/FaFacebookF';
import { FaInstagram } from '@react-icons/all-files/fa/FaInstagram';
import { FaLinkedin } from '@react-icons/all-files/fa/FaLinkedin';
import emailjs from '@emailjs/browser';

export function Footer() {
  const form = useRef();
  const [emailSent, setEmailSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_8rqx6dc', 'template_g3rj8zj', form.current, 'user_S2Tf1HPc1XphaH0QJrbgV')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      })
      .finally(() => {
        setEmailSent(true);
      });
  };

  return (
    <div className={styles.container}>
      <img src='/static/images/logo2.png' alt='logo' className={styles.imgStair} />

      <div className={styles.buttonContainer}>
        <a href='#' className={styles.roundButton}>
          <FaLinkedin color='white' size={32} />
        </a>

        <a href='#' className={styles.roundButton}>
          <FaInstagram color='white' size={32} />
        </a>
        <a href='#' className={styles.roundButton}>
          <FaFacebookF color='white' size={32} />
        </a>
        <a href='#' className={styles.roundButton}>
          <FaTwitter color='white' size={32} />
        </a>
      </div>

      <div style={{ padding: '20px' }}>
        <h3 style={{ textAlign: 'center' }}>
          Contato
        </h3>
        <div style={{ display: 'flex', width: '100vw', gap: '20px', justifyContent: 'center' }}>
            <div><strong>Telefone:</strong> (79) 3085-1513</div>
            <div><strong>Email:</strong> contato@quick.app.br</div>
            <div><strong>Endereço:</strong> Rua Manoel Donizetti Viera 08, bairro Luzia, Aracaju SE</div>
        </div>
        {
            emailSent ? <div style={{ textAlign: 'center', color: 'green', marginTop: '10px' }}>Email enviado com sucesso!</div> : (
              <form ref={form} onSubmit={sendEmail} className={styles.form}>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                    <label className={styles.label}>Nome</label>
                    <input className={styles.input} type="text" name="user_name" />
                  </div>
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                    <label className={styles.label}>Email</label>
                    <input className={styles.input} type="email" name="user_email" />
                  </div>
                </div>
                <label className={styles.label}>Mensagem</label>
                <textarea className={styles.textArea} name="message" />
                <input className={styles.submit} type="submit" value="Enviar" />
              </form>
            )
          }
      </div>
    </div>
  )
}