import React, { useState } from 'react';
import { FooterMobile } from './FooterMobile';
import { SectionHomeMobile } from "./SectionHomeMobile";
import * as styles from './styles.module.scss';
import { slide as Menu } from 'react-burger-menu';
import { burgerMenuStyles } from '../../theme/buger-menu.js';
import { FaCaretRight } from '@react-icons/all-files/fa/FaCaretRight';
import { SectionFeaturesMobile } from './SectionFeaturesMobile';
import { SectionProviderMobile } from './SectionProviderMobile';
import { SectionHireAProviderMobile } from './SectionHireAProviderMobile';
import { SectionHowItWorksMobile } from './SectionHowItWorksMobile';
import { SectionContactMobile } from './SectionContactMobile';

const TABS = {
  HOME: 'home',
  FEATURES: 'features',
  HIRE_A_PROVIDER: 'hire-a-provider',
  PROVIDER: 'provider',
  HOW_IT_WORKS: 'how-it-works',
  CONTACT: 'contact',
};

export function LayoutMobile() {
  const [selectedTab, setSelectedTab] = useState(TABS.HOME);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  function showSettings(e) {
    e.preventDefault();
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.navbar}>
          <Menu
            styles={burgerMenuStyles}
            isOpen={isMenuOpen}
            onOpen={() => setIsMenuOpen(true)}
          >
            <a onClick={() => setIsMenuOpen(false)} href={`#${TABS.HOME}`} className="menu-item">Home <FaCaretRight size={24} color='#39B4EA' /></a>
            <a onClick={() => setIsMenuOpen(false)} href={`#${TABS.FEATURES}`} className="menu-item">Funcionalidades <FaCaretRight size={24} color='#39B4EA' /></a>
            <a onClick={() => setIsMenuOpen(false)} href={`#${TABS.HIRE_A_PROVIDER}`} className="menu-item">Contrate um prestador <FaCaretRight size={24} color='#39B4EA' /></a>
            <a onClick={() => setIsMenuOpen(false)} href={`#${TABS.PROVIDER}`} className="menu-item">Seja um prestador <FaCaretRight size={24} color='#39B4EA' /></a>
            <a onClick={() => setIsMenuOpen(false)} href={`#${TABS.HOW_IT_WORKS}`} className="menu-item">Como funciona <FaCaretRight size={24} color='#39B4EA' /></a>
            <a onClick={() => setIsMenuOpen(false)} href={`#${TABS.CONTACT}`} className="menu-item">Contato <FaCaretRight size={24} color='#39B4EA' /></a>
          </Menu>
          
        </div>
        
        {/* {selectedTab === TABS.HOME ? ( */}
          <SectionHomeMobile id={TABS.HOME} />
        {/* ) : selectedTab === TABS.FEATURES ? ( */}
          <SectionFeaturesMobile id={TABS.FEATURES} />
        {/* ) : selectedTab === TABS.HIRE_A_PROVIDER ? ( */}
          <SectionHireAProviderMobile id={TABS.HIRE_A_PROVIDER} />
        {/* ) : selectedTab === TABS.PROVIDER ? ( */}
          <SectionProviderMobile id={TABS.PROVIDER} />
        {/* ) : selectedTab === TABS.HOW_IT_WORKS ? ( */}
          <SectionHowItWorksMobile id={TABS.HOW_IT_WORKS} />
        {/* ) : selectedTab === TABS.CONTACT ? ( */}
          <SectionContactMobile id={TABS.CONTACT} />
        {/* ) : null } */}
        <FooterMobile className={styles.footer} />
      </div>
    </>
  )
}