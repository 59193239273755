import React from 'react';
import { data } from '../../../mocks/data';
import * as styles from './styles.module.scss';

export function SectionProvider({ id }) {
  return (
    <div className={styles.container} id={id}>
      <div className={styles.contentWrapper}>
        {data.provider}
      </div>
      <img src='/static/images/be-a-provider.png' alt='logo' className={styles.imgBeAProvider}/>
      <button className={styles.signupButton}>CRIAR CONTA</button>
    </div>
  )
}