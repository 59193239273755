import React from 'react';
import { Footer } from "./Footer";
import { Navbar } from "./Navbar";
import SecionFeatures from "./SectionFeatures";
import SectionHireAProvider from "./SectionHireAProvider";
import SectionHome from "./SectionHome";
import SectionHowItWorks from "./SectionHowItWorks";
import { SectionProvider } from "./SectionProvider";

export function LayoutWeb() {
  return (
    <>
      <Navbar />
      <SectionHome id='home' />
      <SecionFeatures id='funcionalidades' />
      <SectionHireAProvider id='contrate-um-prestador' />
      <SectionProvider id='seja-um-prestador' />
      <SectionHowItWorks id='como-funciona'/>
      <Footer />
    </>
  )
}