import React, { useEffect, useState } from "react"
import './global.scss'
import "@fontsource/open-sans" // Defaults to weight 400 with all styles included.
import "@fontsource/open-sans/500.css"
import { LayoutWeb } from "../components/LayoutWeb"
import { LayoutMobile } from "../components/LayoutMobile"

// markup
const IndexPage = () => {
  const [windowWidth, setWindowWidth] = useState(599);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    if (typeof window !== `undefined`) {
      handleResize();
      window.addEventListener('resize', handleResize);
    }
  }, []);

  return (
    <main>
      <title>Home Page</title>
      {windowWidth >= 600 ? (
        <LayoutWeb />
      ) : (
        <LayoutMobile />
      )}
    </main>
  )
}

export default IndexPage
