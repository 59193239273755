import React, { useRef } from "react"
import emailjs from '@emailjs/browser';
import * as styles from './styles.module.scss';
import { useState } from "react";

export function SectionContactMobile({ id }) {
  const form = useRef();
  const [emailSent, setEmailSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_8rqx6dc', 'template_g3rj8zj', form.current, 'user_S2Tf1HPc1XphaH0QJrbgV')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      })
      .finally(() => {
        setEmailSent(true);
      });
  };

  return (
    <div style={{ padding: '20px', height: '70vh' }} id={id}>
      <h3 style={{ textAlign: 'center' }}>
        Contato
      </h3>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '40px', justifyContent: 'center' }}>
          <div><strong>Telefone:</strong> (79) 3085-1513</div>
          <div><strong>Email:</strong> contato@quick.app.br</div>
          <div><strong>Endereço:</strong> Rua Manoel Donizetti Viera 08, bairro Luzia, Aracaju SE</div>
          {
            emailSent ? 'Email enviado com sucesso!' : (
              <form ref={form} onSubmit={sendEmail} className={styles.form}>
                <label className={styles.label}>Nome</label>
                <input className={styles.input} type="text" name="user_name" />
                <label className={styles.label}>Email</label>
                <input className={styles.input} type="email" name="user_email" />
                <label className={styles.label}>Mensagem</label>
                <textarea className={styles.input} name="message" />
                <input className={styles.submit} type="submit" value="Enviar" />
              </form>
            )
          }
      </div>
    </div>
  )
}