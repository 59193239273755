import React from 'react';
import * as styles from './styles.module.scss';
import { FaGlobe } from '@react-icons/all-files/fa/FaGlobe';
import { FaRegUser } from '@react-icons/all-files/fa/FaRegUser';

export function Navbar() {
  return (
    <div className={styles.container}>
      <a href='#' className={styles.buttonHome}>
        Home
      </a>
      <div className={styles.rightButtonsWrapper}>
        <a href='#' className={styles.buttonTransparent}>
          <FaGlobe /> PT-BR
        </a>
        <a href='#' className={styles.buttonTransparent}>
          <FaRegUser /> Entre no app
        </a>
        <a href='#' className={styles.buttonSignUp}>
          Cadastre-se
        </a>
      </div>
    </div>
  )
}