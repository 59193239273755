import { FaFacebookF } from '@react-icons/all-files/fa/FaFacebookF';
import { FaInstagram } from '@react-icons/all-files/fa/FaInstagram';
import { FaLinkedin } from '@react-icons/all-files/fa/FaLinkedin';
import { FaTwitter } from '@react-icons/all-files/fa/FaTwitter';
import React from 'react';
import * as styles from './styles.module.scss';

export function SectionHomeMobile({ className, id }) {
  return (
    <div className={styles.container} id={id}>
      <img src='/static/images/mobile/QUICK_IMAGEM_CENTRAL.png' alt='logo' className={styles.imgHome} />
      <img src='/static/images/logo.png' alt='logo' className={styles.imgLogo} />
      <div className={styles.sectionDownload}>
        <a href='http://www.google.com' target='_blank'>
          <img src='/static/images/button-download-apple.png' alt='logo' className={styles.imgAppStore}/>
        </a>
        <a href='http://www.google.com' target='_blank'>
          <img src='/static/images/button-download-android.png' alt='logo' className={styles.imgAppStore}/>
        </a>
      </div>
      <div className={styles.buttonContainer}>
        <a href='#' className={styles.roundButton}>
          <FaFacebookF color='white' size={24} />
        </a>
        <a href='#' className={styles.roundButton}>
          <FaTwitter color='white' size={24} />
        </a>
        <a href='#' className={styles.roundButton}>
          <FaInstagram color='white' size={24} />
        </a>
        <a href='#' className={styles.roundButton}>
          <FaLinkedin color='white' size={24} />
        </a>
      </div>
    </div>
  )
}