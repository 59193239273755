import React from 'react';
import * as styles from './styles.module.scss';

export default function SectionHowItWorks({ id }) {
  return (
    <div className={styles.container} id={id}>
      <div className={styles.videoContainer}>
        <span className={styles.title}>COMO <span style={{ fontWeight: 'bold' }}>FUNCIONA</span></span>
        <iframe width="800" height="480"
          src="https://www.youtube.com/embed/tgbNymZ7vqY">
        </iframe>
      </div>
      <img src='/static/images/wave.png' alt='logo' className={styles.imgWave} />
      <div className={styles.buttonWrapper}>
        <a href='http://www.google.com' target='_blank'>
          <img src='/static/images/button-download-apple.png' alt='logo' className={styles.imgAppStore}/>
        </a>
        <a href='http://www.google.com' target='_blank'>
          <img src='/static/images/button-download-android.png' alt='logo' className={styles.imgAppStore}/>
        </a>
      </div>
    </div>
  )
}