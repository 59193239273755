export const burgerMenuStyles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '30px',
    height: '20px',
    left: '10px',
    top: '15px',
  },
  bmBurgerBars: {
    background: 'white'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '50%',
  },
  bmMenu: {
    background: '#1647A8',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em',
    borderBottomRightRadius: '50px',
    borderBottomLeftRadius: '50px',
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#b8b7ad',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  bmItem: {
    display: 'flex',
    color: 'white',
    textDecoration: 'none',
    padding: '0.5em',
    textTransform: 'uppercase',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)'
  }
};