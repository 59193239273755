import React from 'react';
import * as styles from './styles.module.scss';

export function SectionHowItWorksMobile({ id }) {
  return (
    <div className={styles.container} id={id}>
      <div className={styles.videoContainer}>
        <span className={styles.label1}>COMO<br/> <strong>FUNCIONA</strong></span>
        <iframe width="100%" height="280px"
          src="https://www.youtube.com/embed/tgbNymZ7vqY">
        </iframe>
        <img src='/static/images/wave.png' alt='logo' className={styles.imgWave} />
      </div>
      <div className={styles.sectionDownload}>
        <a href='http://www.google.com' target='_blank'>
          <img src='/static/images/button-download-apple.png' alt='logo' className={styles.imgAppStore}/>
        </a>
        <a href='http://www.google.com' target='_blank'>
          <img src='/static/images/button-download-android.png' alt='logo' className={styles.imgAppStore}/>
        </a>
      </div>
    </div>
  )
}