export const data = {
  hireAProvider: `
    Seja impactado pela Quick: agendamento de serviço na palma da sua mão de forma simples e rápida

    Seja o Quick, conectamos você consumidor ao profissional de climatização. Uma plataforma de solicitação de serviço à sua disposição de forma prática, rápida, segura e que cabe na sua agenda. Gerenciamos e acompanhamos de forma qualificada a sua demanda de serviço de manutenção preventiva e corretiva, jateamento e instalação do seu ar condicionado. Escolha ter o controle do clima do seu lar e surpreenda-se.
  `,
  provider: `
    Fazemos sua única preocupação ser a qualidade do seu serviço

    Seja Quick e ganhe tranquilidade, tornamos o seu dia a dia mais simples sem a necessidade de arranjar clientes. Te conectamos diretamente ao serviço de forma simples, rápida e com a certeza do recebimento e valorização da sua mão de obra. Fazemos com que a sua preocupação diária seja apenas com aquilo que você sabe fazer de melhor que é a execução do serviço. Transforme sua carreira profissional e melhore sua qualidade de vida sendo nosso parceiro. 
  `,
  features: [
      `PAINEL DE SERVIÇOS: Tecnologia na palma da mão. Veja todo o serviço de climatização simplificado.`,
      `INSPEÇÃO AGENDADA: Praticidade na  hora de marcar a visita! Serviços disponíveis que cabem na sua rotina.`,
      `GEOLOCALIZAÇÃO: Encontre o mais próximo de você. Conecte-se aos melhores profissionais.`,
      `STATUS ATUALIZADOS: Controle e acompanhe as solicitações e os serviços prestados.`,
      `RELATÓRIO VIRTUAL: Ficou mais simples ter acesso detalhado aos relatórios finais de serviço.`,
      `PAGAMENTOS: Realize os pagamentos sem burocracia.`,
  ],
}