import React from 'react';
import { data } from '../../../mocks/data';
import * as styles from './styles.module.scss';

export default function SectionHireAProvider({ id }) {
  return (
    <div className={styles.container} id={id}>
      <div className={styles.hireContainer}>
        <div className={styles.titleContainer}>
          <span className={styles.label1}>Contrate um</span>
          <span className={styles.label2}>Prestador</span>
        </div>
        <img src='/static/images/hand-shake2.png' alt='logo' className={styles.imageHire} />
      </div>
      <div className={styles.contentContainer}>
        <img src='/static/images/ar-condicionado.png' alt='logo' className={styles.airImage} />
        <div className={styles.contentWrapper}>
          {data.hireAProvider}
          <button className={styles.signupButton}>CADASTRAR</button>
        </div>
      </div>
    </div>
  )
}