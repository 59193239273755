import React from 'react';
import { data } from '../../../mocks/data';
import * as styles from './styles.module.scss';

export function SectionHireAProviderMobile({ id }) {
  return (
    <div className={styles.container} id={id}>
      <img src='/static/images/hand-shake2.png' alt='logo' className={styles.imgMain}/>

      <div className={styles.titleContainer}>
        <span className={styles.label1}>
          Contrate um<br/>
        </span>
        <span className={styles.label2}>
          prestador
        </span>
      </div>

      <div className={styles.contentWrapper}>
        {data.hireAProvider}
        <button className={styles.signupButton}>CADASTRAR</button>
      </div>
    </div>
  )
}