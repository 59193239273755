import React from "react"
import { data } from "../../../mocks/data"
import * as styles from "./styles.module.scss"
import { FaCheck } from '@react-icons/all-files/fa/FaCheck';

export function SectionFeaturesMobile({ id }) {
  return (
    <div className={styles.container} id={id}>
      <img src='/static/images/features.png' alt='logo' className={styles.imgStairs}/>
      
      <div className={styles.containerText}>
        {
          data.features.map(feature => {
            return (
              <h3 className={styles.featureText}>
                <FaCheck className={styles.textGreen} /> {feature}
              </h3>
            )
          })
        }
      </div>
    </div>
  )
}