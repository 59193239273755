import React from 'react';
import * as styles from './styles.module.scss';

const buttons = [
  {
    title: 'Funcionalidades',
    url: '#funcionalidades',
  },
  {
    title: 'Contrate um prestador',
    url: '#contrate-um-prestador',
  },
  {
    title: 'Seja um prestador',
    url: '#seja-um-prestador',
  },
  {
    title: 'Como funciona',
    url: '#como-funciona',
  },
]

export default function SectionHome({ id }) {
  return (
    <div className={styles.container} id={id}>
      <div className={styles.sectionDownload}>
        <img src='/static/images/logo.png' alt='logo' />
        <a href='http://www.google.com' target='_blank'>
          <img src='/static/images/button-download-apple.png' alt='logo' className={styles.imgAppStore}/>
        </a>
        <a href='http://www.google.com' target='_blank'>
          <img src='/static/images/button-download-android.png' alt='logo' className={styles.imgAppStore}/>
        </a>
      </div>
      <div className={styles.sectionImgBanner}>
        <div className={styles.buttonsContainer}>
          {buttons.map(button => (
            <a href={button.url} className={styles.button}>
              {button.title}
            </a>
          ))}
        </div>
        <img src='/static/images/banner2.png' alt='logo' className={styles.imgBanner} />
      </div>
    </div>
  )
}